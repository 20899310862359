@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-carousel-container .slick-track,
.slick-carousel-container .slick-slide {
  background: transparent !important;
}

.slick-slide {
  padding: 0 10px; /* Adjust the spacing between slides */
}

/* Custom CSS for react-slick */
.slick-slide {
  background-color: transparent !important;
}

.slick-track {
  background-color: transparent !important;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
